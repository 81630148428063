html,
body {
    height: 100%;
}

body {
    font-family: 'Proxima Nova', 'Trebuchet MS', 'Lucida Sans', Arial, sans-serif;
    float: none;
    margin: 0px auto;
    background-color: #F9F9F9;
}

.container {
    padding-left: 5px;
    padding-right: 5px;
}

/* Set padding to keep content from hitting the edges */

.body-content {
    padding-left: 15px;
    padding-right: 15px;
}

/* Set width on the form input elements since they're 100% wide by default */

input,
select,
textarea {
    max-width: 280px;
}

#floating_link {
    position: fixed;
    right: 0;
    top: 400px;
    display: block;
    width: 50px;
    height: 125px;
    text-indent: -10000px;
    overflow: hidden;
}

.headline {
    font-family: 'Proxima Nova', 'Trebuchet MS', 'Lucida Sans', Arial, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    color: #38393a;
    font-size: 16px;
}

.text-uppercase {
    text-transform: uppercase;
}

.body-copy {
    font-style: normal;
    color: #38393a;
    font-weight: 500;
    font-family: 'Proxima Nova', 'Trebuchet MS', 'Lucida Sans', Arial, sans-serif;
    font-size: 14px;
}

.mt-15 {
    margin-top: 15px;
}

.body-copy-bold {
    font-style: normal;
    color: #38393a;
    font-weight: 700;
    font-family: 'Proxima Nova', 'Trebuchet MS', 'Lucida Sans', Arial, sans-serif;
    font-size: 14px;
}

.body-copy-large {
    font-style: normal;
    color: #38393a;
    font-weight: 700;
    font-family: 'Proxima Nova', 'Trebuchet MS', 'Lucida Sans', Arial, sans-serif;
    font-size: 16px;
}

.body-copy-bold-large {
    font-style: normal;
    color: #38393a;
    font-weight: 900;
    font-family: 'Proxima Nova', 'Trebuchet MS', 'Lucida Sans', Arial, sans-serif;
    font-size: 16px;
}

.body-light {
    font-style: normal;
    font-weight: 300;
    font-family: 'Proxima Nova', 'Trebuchet MS', 'Lucida Sans', Arial, sans-serif;
}

.body-copy-gray {
    font-style: normal;
    color: #9E9D9D;
    font-weight: 500;
    font-family: 'Proxima Nova', 'Trebuchet MS', 'Lucida Sans', Arial, sans-serif;
    font-size: 14px;
}

.page-wrap {
    max-width: 1100px;
    padding: 10px 5px 20px 5px;
    overflow-x: hidden;
}

.text-link-default {
    font-weight: 700;
    font-family: 'Proxima Nova', 'Trebuchet MS', 'Lucida Sans', Arial, sans-serif;
    font-style: normal;
    font-size: 14px;
}

.revspring-logo {
    padding-top: 3px;
}

.revspring-logo-text {
    font-style: normal;
    color: #9E9D9D;
    font-weight: 300;
    font-family: 'Proxima Nova', 'Trebuchet MS', 'Lucida Sans', Arial, sans-serif;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 20px;
}

.headline-small {
    font-family: 'Proxima Nova', 'Trebuchet MS', 'Lucida Sans', Arial, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    color: #38393a;
    font-size: 14px;
}

.text-link-small {
    font-weight: 700;
    font-family: 'Proxima Nova', 'Trebuchet MS', 'Lucida Sans', Arial, sans-serif;
    font-style: normal;
    font-size: 14px;
}

input,
select,
textarea {
    color: #38393a;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #C6C6C6;
}

.underline {
    border-bottom: thin;
    border-bottom-color: #DCD5D5;
    border-bottom-style: solid;
}

.content:before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    z-index: -1;
    display: block;
    background-image: url('/Content/strawberries-close-up.jpg');
    width: 1900px;
    height: 800px;
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}

.content {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 0;
    margin-left: 20px;
    margin-right: 20px;
}

.no-pad-no-margin {
    margin: 0 !important;
    padding: 0 !important;
}

.no-float {
    float: none;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.validation-errors {
    color: red !important;
}

input.validation-errors,
textarea.validation-errors,
select.validation-errors {
    border: 1px solid red !important;
    color: #38393A;
}

.alert {
    padding: 15px;
    margin: 20px 0;
    border: 1px solid transparent;
    border-radius: initial;
    background-image: none;
    box-shadow: none;
}

.alert-warning {
    background-color: #ffc266;
    color: #38393A;
    font-weight: 500;
}

.alert-danger {
    background-color: #e7c3c3;
}

.panel-small-body {
    padding: 15px;
}

.panel-body {
    padding: 30px 0px 30px 0px;
}

.panel {
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .10);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .10);
    border: 1px solid #C6C6C6;
}

.button-row {
    margin-bottom: 60px;
    margin-top: 20px;
}

button {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin: 4px 2px;
    cursor: pointer;
    width: 200px;
    height: 45px;
}

.primary-white {
    border-width: 0;
    color: white;
    background-color: #35b1e6;
    border-color: #35b1e6;
}

.secondary-white {
    border-width: 0;
    color: white;
    background-color: #ffc266;
    border-color: #ffc266;
}

.white-primary {
    color: #35b1e6;
    background-color: white;
    border-width: 1px;
    border-color: #35b1e6;
    border-style: solid;
}

.survey-panel {
    border: 1px solid #ffc266;
}

.secondary-background {
    background-color: #ffc266;
    border-color: #ffc266;
}

.site-color {
    color: #35b1e6;
}

/* fix for centering creditcard icon on payment-method-manager "dropdown" */

.enter-new-method-logo .generic-logo-img {
    float: left;
}

/* Temp location for LiveHelpNow overrides */

div#lhnHocButton {
    z-index: 3;
}

div#lhnHocButton.lhnslide {
    right: 40px;
    font-weight: 700;
    font-family: 'Proxima Nova', 'Trebuchet MS', 'Lucida Sans', Arial, sans-serif;
    font-style: normal;
    font-size: 15px;
    transition-duration: .3s;
}

div#lhnHocButton div.lhnHocChatTxtCont span {
    font-style: normal;
    font-weight: 600;
    font-family: 'Proxima Nova', 'Trebuchet MS', 'Lucida Sans', Arial, sans-serif;
    font-size: 15px;
    padding-top: 5px;
    letter-spacing: 0px !important;
}

div#lhnHocButton div.lhnHocChatTxtCont {
    background-color: #35b1e6;
}

div#lhnHocButton div.lhnHocChatBtn {
    background-color: #35b1e6;
}

div#lhnHocButton div.lhnHocBackBubble:after {
    border-color: #35b1e6 #35b1e6 transparent transparent;
}

div#lhnHocButton div.lhnHocChatBtnCont {
    background-color: #35b1e6;
}

div#lhnHocButton div.lhnHocBackBubble {
    background-color: #35b1e6;
}

div#lhnHocButton div.lhnHocChatTxtCont {
    background-color: #35b1e6;
    padding-top: 8px;
}

div#lhnHocButton.lhnslide .lhnHocChatBtnCont {
    padding: 0px 12px 7px;
    border-radius: 0px 0px 0px 0px;
    margin-top: -2px;
}

div#lhnHocButton.lhnslide div.lhnHocChatBubble {
    margin-right: 0;
    visibility: hidden;
}

div#lhnHocButton.lhnslide div.lhnHocChatBubble {
    height: 0;
    width: 0;
}

div#lhnHocButton div.lhnHocChatTxtCont {
    height: 20px !important;
}

div#lhnHocButton.lhnslide {
    position: fixed;
    top: auto;
    bottom: 131px;
    right: -25px;
    transform: rotate(90deg);
    font-weight: 700;
    font-family: 'Proxima Nova', 'Trebuchet MS', 'Lucida Sans', Arial, sans-serif;
    font-style: normal;
    font-size: 16px;
}

div#lhnHocButton div.lhnHocChatTxtCont span {
    font-style: normal;
    font-weight: 600;
    font-family: 'Proxima Nova', 'Trebuchet MS', 'Lucida Sans', Arial, sans-serif;
    font-size: 16px;
    padding-top: 5px;
    letter-spacing: 0px !important;
}

#lhnHelpOutCenter div.lhnWindow-header {
    background-color: #35b1e6;
}

#lhnHelpOutCenter div.lhnWindow-body * {
    font-style: normal;
    font-weight: 600;
    font-family: 'Proxima Nova', 'Trebuchet MS', 'Lucida Sans', Arial, sans-serif;
    font-size: 16px;
    text-shadow: 0px 0px 0 rgba(0, 0, 0, 0.25);
    text-transform: initial;
    border-radius: 0px 0px 0px 0px;
}

#lhnHelpOutCenter div.lhnWindow-header h3 {
    font-weight: 600;
    font-family: 'Proxima Nova', 'Trebuchet MS', 'Lucida Sans', Arial, sans-serif;
    letter-spacing: .3px;
}

#lhnHelpOutCenter input.lhnInput,
#lhnHelpOutCenter textarea.lhnInput,
#lhnHelpOutCenter select.lhnInput,
#lhnHelpOutCenter input#txtKeyword,
#lhnHelpOutCenter input.sf_txtBox,
#lhnHelpOutCenter select.sf_ddl,
#lhnHelpOutCenter textarea.sf_txtArea {
    background-color: #f3f4f4;
    border: 1px solid #c3c4c4;
}

#lhnHelpOutCenter button.lhnFormButton,
#lhnHelpOutCenter button.form_submit {
    background-color: #35b1e6;
}

#lhnHelpOutCenter div.lhnCustomerMessage div.lhnWindow-message {
    background-color: #35b1e6;
}

div#lhnHocInvite div.lhnHocInviteCont {
    background-color: #35b1e6;
    font-weight: 600;
    font-family: 'Proxima Nova', 'Trebuchet MS', 'Lucida Sans', Arial, sans-serif;
    letter-spacing: .3px;
}

div#lhnHocInvite div.lhnHocInviteButton div.lhnHocInviteButton.lhnstart {
    background-color: #35b1e6;
    color: #ffffff;
    border-radius: 0px 0px 0px 0px;
}

div#lhnHocInvite div.lhnHocInviteButton.lhnstart {
    background-color: #35b1e6;
    color: #ffffff;
    border-radius: 0px 0px 0px 0px;
}

div#lhnHocInvite div.lhnHocInviteCont {
    border-radius: 0px 0px 0px 0px;
}

#lhnHelpOutCenter div.lhnWindow-chat_input button {
    background-color: #35b1e6;
}

#lhnHelpOutCenter div.lhnChatActionsMenu img {
    background-color: #35b1e6;
}

#lhnHelpOutCenter div.lhnCustomerMessage .lhnWindow-message:after {
    border-color: transparent transparent transparent #35b1e6;
}

#lhnHelpOutCenter div.lhnPrompt button.lhnFormButton {
    border: 2px solid #35b1e6;
    border-radius: 0px 0px 0px 0px;
}

div#lhnHocInvite div.lhnHocInviteButton {
    border-radius: 0px 0px 0px 0px;
    background-color: #35b1e6;
}

#lhnHelpOutCenter div.lhnWindow-chat_input button:hover {
    background-color: #35b1e6;
}

#lhnHelpOutCenter div.lhnWindow-chat_input button.lhnShow {
    text-shadow: 0px 0px 0 rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 0px 0px;
    letter-spacing: .3px;
}

#lhnHelpOutCenter div.lhnPrompt button.lhnFormButton.lhnPromptDecline {
    background-color: #ffffff;
    color: #35b1e6;
}

/* Temp location for LiveHelpNow overrides */

.site-secondary-color {
    color: #ffc266;
}

.mydrp {
    width: 100% !important;
    max-width: 262px;
}

/* Override site color and glyph color for the breadcrumb home icon */

.breadcrumb-home .glyphicon-home {
    color: gray !important;
}

/* Very specific CSS for card logos on statement history */

.statement-history .payment-wrap .payment-row .method-logo .logo-img {
    height: 38px;
    width: 65px;
}

.avatar-small .avatar-icon .icon {
    padding-top: 0 !important;
}

/*specific css for active-user component avatar icon on profile page*/

.avatar-circle .avatar-large .avatar-icon .icon {
    font-size: 50px !important;
}

/* disable double tap zoom  */
.disable-dbl-tap-zoom {
    touch-action: manipulation;
}

@media (max-width: 1199px) {
    button,
    .button-choice {
        width: 155px;
    }
}

@media (max-width: 991px) {
    .carousel-wrap {
        padding-top: 40px;
    }

    .no-pad-sm {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: 768px) {
    /*specific css for active-user component avatar icon on home page profile widget*/
    .profile .avatar-large .avatar-icon .icon {
        font-size: 50px !important;
    }
}

@media (max-width: 767px) {
    .xs-no-pad-no-margin {
        padding: 0 !important;
    }
    /*specific css for active-user component avatar icon on home page profile widget*/
    .profile .avatar-large .avatar-icon .icon {
        font-size: 40px !important;
        padding-top: 0 !important;
    }
    /*specific css for active-user component initials on profile page*/
    .avatar-circle .avatar-circle-large .initials-large {
        top: 30px !important;
    }
}

@media (max-width: 479px) {
    .no-pad-xxs {
        padding-left: 0;
        padding-right: 0;
    }
    .popover {
        left: -40px !important;
    }
    .popover-image {
        width: 230px !important;
    }
}

@media (max-width: 360px) {
    button,
    .button-choice {
        width: 120px;
    }
}

/*Carousel CSS that doesn't apply if placed in msbcarousel component css*/

.carousel-wrap>.carousel>.carousel-control {
    display: none;
}

.carousel-wrap>.carousel>.carousel-indicators {
    bottom: -30px;
}

.carousel-wrap>.carousel>.carousel-indicators li {
    margin-right: 5px;
}

.carousel .carousel-inner {
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

/*CSS for ng2-dropdown module since as much as possible in the module should be generic*/

.ng2-dropdown-container.payment-method button {
    color: #35b1e6;
    font-weight: bold;
    border: 0px;
    text-align: center;
}

.ng2-dropdown-container.payment-method button:hover {
    color: #88cbe8;
}

.row-centered {
    text-align: center;
}

.col-centered {
    display: inline-block;
    float: none;
    /* reset the text-align */
    text-align: left;
    /* inline-block space fix */
    margin-right: -4px;
}

i.help-icon:focus {
    outline: none;
}

i.help-icon:hover {
    cursor: pointer;
}

i.glyphicon.glyphicon-usd {
    padding-right: 3px !important;
}

.svg-arrow-primary {
    fill: #949292;
}

.glyphicon-chevron-down,
.glyphicon-chevron-up {
    color: #121313;
}

/*CSS for tooltips*/

.tooltip.in {
    /* We don't want images/content inside the tooltip to be transparent */
    opacity: 1;
}

.tooltip-inner {
    background-color: rgba(158, 157, 157, .9);
    border-radius: 0px;
    max-width: 500px;
    max-height: 500px;
}

.tooltip.top .tooltip-arrow {
    border-top-color: rgba(158, 157, 157, .9);
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: rgba(158, 157, 157, .9);
}

.tooltip.right .tooltip-arrow {
    border-right-color: rgba(158, 157, 157, .9);
}

.tooltip.left .tooltip-arrow {
    border-left-color: rgba(158, 157, 157, .9);
}

.popover {
    background-color: rgba(158, 157, 157, .9);
    border-radius: 0px;
    max-width: 500px;
    width: auto;
    position: absolute !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.popover-image {
    width: 400px;
}

.popover.content {
    background-color: rgba(158, 157, 157, .9);
}

.popover.top>.arrow:after {
    border-top-color: rgba(158, 157, 157, .9);
}

.popover.bottom>.arrow:after {
    border-bottom-color: rgba(158, 157, 157, .9);
}

.popover.right>.arrow:after {
    border-right-color: rgba(158, 157, 157, .9);
}

.popover.left>.arrow:after {
    border-left-color: rgba(158, 157, 157, .9);
}

/*CSS for superscripts applied via the currency display pipe*/

sup {
    top: -.5em;
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

.statement-balance sup,
.bucket-balance sup {
    font-size: 16px;
}

.text-center.dollars sup {
    font-size: 55%;
}

.payment-amount sup {
    font-size: 55%;
}

.payment-amounts-step-three sup {
    top: -.25em;
}

.irs-bar {
    background: #35b1e6;
    border: 1px solid #35b1e6;
}

.irs-single {
    background: #35b1e6;
}

.irs-bar-edge {
    background: #35b1e6;
    border: 1px solid #35b1e6;
}

.irs-slider,
.irs-slider:hover {
    background: #35b1e6;
    border: 1px solid #35b1e6;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #bababa;
    text-align: left;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #bababa;
    text-align: left;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #bababa;
    text-align: left;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #bababa;
    text-align: left;
}

/* Modal */
.modal-header .close {
    text-align: right;
}
.modal-header button {
    font-size: 25pt;
    height: 25px;
    width: 25px;
}
.modal-header button:focus {
    outline: none;
}

/* These next two override styles set in vendor.css */
/* for the X button on our modal headers. */
.close {
    opacity: 0.8 !important;
}
.close:hover,
.close:focus {
    opacity: 1 !important
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}