/* Import this if you are using modernizr (recommended) 
It means that loaders will fallback to a text state of simply saying "Loading..." in older browsers
*/
.no-cssanimations .load-container .loader {
  text-indent: 0;
  text-align: center;
  color: #FFF;
  font-size: 17px;
  background: none;
  border: 0 none;
  width: auto;
  height: auto;
  margin: 1em auto;
  overflow: visible;
  box-shadow: none;
  -webkit-animation: none;
  animation: none;
}
.no-cssanimations .load-container .loader:before,
.no-cssanimations .load-container .loader:after {
  display: none;
}

.loader {
  overflow: hidden!important;
  margin: 100px auto;
  font-size: 15px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #35b1e6, 1.8em -1.8em 0 0em rgba(53,177,230, 0.2), 2.5em 0em 0 0em rgba(53,177,230, 0.2), 1.75em 1.75em 0 0em rgba(53,177,230, 0.2), 0em 2.5em 0 0em rgba(53,177,230, 0.2), -1.8em 1.8em 0 0em rgba(53,177,230, 0.2), -2.6em 0em 0 0em rgba(53,177,230, 0.5), -1.8em -1.8em 0 0em rgba(53,177,230, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(53,177,230, 0.7), 1.8em -1.8em 0 0em #35b1e6, 2.5em 0em 0 0em rgba(53,177,230, 0.2), 1.75em 1.75em 0 0em rgba(53,177,230, 0.2), 0em 2.5em 0 0em rgba(53,177,230, 0.2), -1.8em 1.8em 0 0em rgba(53,177,230, 0.2), -2.6em 0em 0 0em rgba(53,177,230, 0.2), -1.8em -1.8em 0 0em rgba(53,177,230, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(53,177,230, 0.5), 1.8em -1.8em 0 0em rgba(53,177,230, 0.7), 2.5em 0em 0 0em #35b1e6, 1.75em 1.75em 0 0em rgba(53,177,230, 0.2), 0em 2.5em 0 0em rgba(53,177,230, 0.2), -1.8em 1.8em 0 0em rgba(53,177,230, 0.2), -2.6em 0em 0 0em rgba(53,177,230, 0.2), -1.8em -1.8em 0 0em rgba(53,177,230, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(53,177,230, 0.2), 1.8em -1.8em 0 0em rgba(53,177,230, 0.5), 2.5em 0em 0 0em rgba(53,177,230, 0.7), 1.75em 1.75em 0 0em #35b1e6, 0em 2.5em 0 0em rgba(53,177,230, 0.2), -1.8em 1.8em 0 0em rgba(53,177,230, 0.2), -2.6em 0em 0 0em rgba(53,177,230, 0.2), -1.8em -1.8em 0 0em rgba(53,177,230, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(53,177,230, 0.2), 1.8em -1.8em 0 0em rgba(53,177,230, 0.2), 2.5em 0em 0 0em rgba(53,177,230, 0.5), 1.75em 1.75em 0 0em rgba(53,177,230, 0.7), 0em 2.5em 0 0em #35b1e6, -1.8em 1.8em 0 0em rgba(53,177,230, 0.2), -2.6em 0em 0 0em rgba(53,177,230, 0.2), -1.8em -1.8em 0 0em rgba(53,177,230, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(53,177,230, 0.2), 1.8em -1.8em 0 0em rgba(53,177,230, 0.2), 2.5em 0em 0 0em rgba(53,177,230, 0.2), 1.75em 1.75em 0 0em rgba(53,177,230, 0.5), 0em 2.5em 0 0em rgba(53,177,230, 0.7), -1.8em 1.8em 0 0em #35b1e6, -2.6em 0em 0 0em rgba(53,177,230, 0.2), -1.8em -1.8em 0 0em rgba(53,177,230, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(53,177,230, 0.2), 1.8em -1.8em 0 0em rgba(53,177,230, 0.2), 2.5em 0em 0 0em rgba(53,177,230, 0.2), 1.75em 1.75em 0 0em rgba(53,177,230, 0.2), 0em 2.5em 0 0em rgba(53,177,230, 0.5), -1.8em 1.8em 0 0em rgba(53,177,230, 0.7), -2.6em 0em 0 0em #35b1e6, -1.8em -1.8em 0 0em rgba(53,177,230, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(53,177,230, 0.2), 1.8em -1.8em 0 0em rgba(53,177,230, 0.2), 2.5em 0em 0 0em rgba(53,177,230, 0.2), 1.75em 1.75em 0 0em rgba(53,177,230, 0.2), 0em 2.5em 0 0em rgba(53,177,230, 0.2), -1.8em 1.8em 0 0em rgba(53,177,230, 0.5), -2.6em 0em 0 0em rgba(53,177,230, 0.7), -1.8em -1.8em 0 0em #35b1e6;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #35b1e6, 1.8em -1.8em 0 0em rgba(53,177,230, 0.2), 2.5em 0em 0 0em rgba(53,177,230, 0.2), 1.75em 1.75em 0 0em rgba(53,177,230, 0.2), 0em 2.5em 0 0em rgba(53,177,230, 0.2), -1.8em 1.8em 0 0em rgba(53,177,230, 0.2), -2.6em 0em 0 0em rgba(53,177,230, 0.5), -1.8em -1.8em 0 0em rgba(53,177,230, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(53,177,230, 0.7), 1.8em -1.8em 0 0em #35b1e6, 2.5em 0em 0 0em rgba(53,177,230, 0.2), 1.75em 1.75em 0 0em rgba(53,177,230, 0.2), 0em 2.5em 0 0em rgba(53,177,230, 0.2), -1.8em 1.8em 0 0em rgba(53,177,230, 0.2), -2.6em 0em 0 0em rgba(53,177,230, 0.2), -1.8em -1.8em 0 0em rgba(53,177,230, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(53,177,230, 0.5), 1.8em -1.8em 0 0em rgba(53,177,230, 0.7), 2.5em 0em 0 0em #35b1e6, 1.75em 1.75em 0 0em rgba(53,177,230, 0.2), 0em 2.5em 0 0em rgba(53,177,230, 0.2), -1.8em 1.8em 0 0em rgba(53,177,230, 0.2), -2.6em 0em 0 0em rgba(53,177,230, 0.2), -1.8em -1.8em 0 0em rgba(53,177,230, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(53,177,230, 0.2), 1.8em -1.8em 0 0em rgba(53,177,230, 0.5), 2.5em 0em 0 0em rgba(53,177,230, 0.7), 1.75em 1.75em 0 0em #35b1e6, 0em 2.5em 0 0em rgba(53,177,230, 0.2), -1.8em 1.8em 0 0em rgba(53,177,230, 0.2), -2.6em 0em 0 0em rgba(53,177,230, 0.2), -1.8em -1.8em 0 0em rgba(53,177,230, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(53,177,230, 0.2), 1.8em -1.8em 0 0em rgba(53,177,230, 0.2), 2.5em 0em 0 0em rgba(53,177,230, 0.5), 1.75em 1.75em 0 0em rgba(53,177,230, 0.7), 0em 2.5em 0 0em #35b1e6, -1.8em 1.8em 0 0em rgba(53,177,230, 0.2), -2.6em 0em 0 0em rgba(53,177,230, 0.2), -1.8em -1.8em 0 0em rgba(53,177,230, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(53,177,230, 0.2), 1.8em -1.8em 0 0em rgba(53,177,230, 0.2), 2.5em 0em 0 0em rgba(53,177,230, 0.2), 1.75em 1.75em 0 0em rgba(53,177,230, 0.5), 0em 2.5em 0 0em rgba(53,177,230, 0.7), -1.8em 1.8em 0 0em #35b1e6, -2.6em 0em 0 0em rgba(53,177,230, 0.2), -1.8em -1.8em 0 0em rgba(53,177,230, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(53,177,230, 0.2), 1.8em -1.8em 0 0em rgba(53,177,230, 0.2), 2.5em 0em 0 0em rgba(53,177,230, 0.2), 1.75em 1.75em 0 0em rgba(53,177,230, 0.2), 0em 2.5em 0 0em rgba(53,177,230, 0.5), -1.8em 1.8em 0 0em rgba(53,177,230, 0.7), -2.6em 0em 0 0em #35b1e6, -1.8em -1.8em 0 0em rgba(53,177,230, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(53,177,230, 0.2), 1.8em -1.8em 0 0em rgba(53,177,230, 0.2), 2.5em 0em 0 0em rgba(53,177,230, 0.2), 1.75em 1.75em 0 0em rgba(53,177,230, 0.2), 0em 2.5em 0 0em rgba(53,177,230, 0.2), -1.8em 1.8em 0 0em rgba(53,177,230, 0.5), -2.6em 0em 0 0em rgba(53,177,230, 0.7), -1.8em -1.8em 0 0em #35b1e6;
  }
}

.loader-white {
  overflow: hidden!important;
  margin: 100px auto;
  font-size: 15px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  text-indent: -9999em;
  -webkit-animation: white-load5 1.1s infinite ease;
  animation: white-load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes white-load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #FFFFFF, 1.8em -1.8em 0 0em rgba(53,177,230, 0.2), 2.5em 0em 0 0em rgba(53,177,230, 0.2), 1.75em 1.75em 0 0em rgba(53,177,230, 0.2), 0em 2.5em 0 0em rgba(53,177,230, 0.2), -1.8em 1.8em 0 0em rgba(53,177,230, 0.2), -2.6em 0em 0 0em rgba(53,177,230, 0.5), -1.8em -1.8em 0 0em rgba(53,177,230, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.70), 1.8em -1.8em 0 0em #FFFFFF, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #FFFFFF, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #FFFFFF, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #FFFFFF, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #FFFFFF, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #FFFFFF, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #FFFFFF;
  }
}
@keyframes white-load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #FFFFFF, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #FFFFFF, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #FFFFFF, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #FFFFFF, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #FFFFFF, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #FFFFFF, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #FFFFFF, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #FFFFFF;
  }
}
